import { useTranslation } from 'react-i18next';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import {
  useGetUserSettingsQuery,
  useUpdateUserSettingsMutation,
} from '@/modules/user';
import LoaderCenter from '@/shared/components/loader-center/loader-center';

import './telegram-notifications.scss';

import type { TgNotifications, UserSettings } from '@/modules/user/store/types';

const b = bemCn('telegram-notifications');

type TelegramNotificationsProps = {
  className?: string;
  inverted?: boolean;
}

const TelegramNotifications = ({ className, inverted }: TelegramNotificationsProps) => {
  const { t } = useTranslation();
  const { data: userSettings } = useGetUserSettingsQuery();
  const [updateUserSettings, { isLoading: isUpdateSettingLoading }] = useUpdateUserSettingsMutation();

  const handleToggles = (value: Partial<TgNotifications>) => {
    const newUserSettings: UserSettings = {
      ...userSettings as UserSettings,
      notifications: {
        ...userSettings?.notifications as TgNotifications,
        ...value,
      }
    };

    updateUserSettings(newUserSettings);
  };

  return (
    <div className={b({ inverted }, className)}>
      <h2 className={b('title')}>
        {t('settings.telegram-notifications', 'Уведомления в Telegram')}
      </h2>

      {(!userSettings || isUpdateSettingLoading) && <LoaderCenter className={b('loading')} />}

      {userSettings && (
        <div className={b('btns')}>
          <button
            className={b('btn', { active: userSettings.notifications.betResults })}
            type='button'
            onClick={() => handleToggles({ betResults: !userSettings.notifications.betResults })}
            disabled={isUpdateSettingLoading}
          >
            <span className={b('btn-text')}>
              {t('settings.bet-results', 'Результаты ставок')}
            </span>
            <span className={b('btn-toggler')}></span>
          </button>

          <button
            className={b('btn', { active: userSettings.notifications.withdrawals })}
            type='button'
            onClick={() => handleToggles({ withdrawals: !userSettings.notifications.withdrawals })}
            disabled={isUpdateSettingLoading}
          >
            <span className={b('btn-text')}>
              {t('settings.withdrawals', 'Статусы заявок на вывод')}
            </span>
            <span className={b('btn-toggler')}></span>
          </button>

          <button
            className={b('btn', { active: userSettings.notifications.notifications })}
            type='button'
            onClick={() => handleToggles({ notifications: !userSettings.notifications.notifications })}
            disabled={isUpdateSettingLoading}
          >
            <span className={b('btn-text')}>
              {t('settings.admin-notifications', 'Уведомления от администрации')}
            </span>
            <span className={b('btn-toggler')}></span>
          </button>
        </div>
      )}

    </div>
  );
};

export default TelegramNotifications;
