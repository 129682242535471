import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { useAppSelector } from '@/shared/hooks';
import { getUserMainData } from '@/modules/user/store/selectors';
import PasswordChange from '../password-change/password-change';
import TelegramChange from '../telegram-change/telegram-change';
import HideBalance from '../hide-balance/hide-balance';
import TimezoneChange from '../timezone-change/timezone-change';
import ThemeChange from '../theme-change/theme-change';
import TelegramNotifications from '../telegram-notifications/telegram-notifications';
import './user-settings.scss';

type UserSettingsProps = {
  className?: string;
  inverted?: boolean;
}

const UserSettings = ({ className, inverted }: UserSettingsProps) => {
  const b = bemCn('user-settings');
  const userMainData = useAppSelector(getUserMainData);

  return (
    <div className={b({ inverted }, className)}>
      <HideBalance className={b('section', { inverted })}
        inverted={inverted}
      />
      <PasswordChange className={b('section', { inverted })}
        inverted={inverted}
      />
      <TelegramChange className={b('section', { inverted })}
        inverted={inverted}
      />
      {userMainData?.telegramVerified && (
        <TelegramNotifications className={b('section', { inverted })}
          inverted={inverted}
        />
      )}
      <TimezoneChange className={b('section', { inverted })}
        inverted={inverted}
      />
      <ThemeChange className={b('section', { inverted })}
        inverted={inverted}
      />
    </div>
  );
};

export default UserSettings;
