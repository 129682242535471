import { IFavoriteGame, IFavoriteMatch, IFavoriteMatchServer, IFavoritesGameServer, IPromocode, IPromocodeServer, IPromocodes, IPromocodesRes, TransactionItem, TransactionItemServer, Transactions, TransactionsRes, UserData, UserDataServer, UserSettings, UserSettingsServer, Wallet, WalletServer, Withdrawals, WithdrawalsItem, WithdrawalsItemServer, WithdrawalsRes } from './types';

export const adapteUserData = (res: UserDataServer): UserData => ({
  id: String(res.id),
  login: res.login,
  email: res.email,
  phone: res.phone,
  phoneCountry: res.phone_country,
  telegramNickname: res.telegram_nickname,
  telegramVerified: res.telegram_verified,
  name: res.name,
  role: res.role,
  created: res.created,
});

export const adapteUserWallet = (wallet: WalletServer): Wallet => ({
  isPrimary: wallet.is_primary,
  currency: wallet.currency,
  amount: wallet.amount,
  bonusAmount: wallet.bonus_amount,
  cashbackLine: wallet.cashback_line,
  cashbackCasino: wallet.cashback_casino,
  maxBetAmount: wallet.max_bet_amount,
});

export const adapteUserWallets = (res: WalletServer[]): Wallet[] =>
  res.map(adapteUserWallet);


export const adapteTransaction = (transaction: TransactionItemServer): TransactionItem => ({
  id: `${transaction.id}`,
  created: transaction.created,
  userIdFrom: `${transaction.user_id_from}`,
  userIdTo: `${transaction.user_id_to}`,
  type: transaction.type,
  amount: transaction.amount,
  currency: transaction.currency,
});

export const adapteTransactions = (res: TransactionsRes): Transactions => ({
  count: res.count,
  items: res.items.map(adapteTransaction),
});

export const adapteWithdrawal = (item: WithdrawalsItemServer): WithdrawalsItem => ({
  id: `${item.id}`,
  created: item.created,
  amount: item.amount,
  currency: item.currency,
  credentials: item.credentials,
  method: item.method,
  status: item.status,
  commentary: item.commentary,
});

export const adapteWithdrawals = (res: WithdrawalsRes): Withdrawals => ({
  count: res.count,
  items: res.items.map(adapteWithdrawal),
});

export const adaptePromocode = (res: IPromocodeServer): IPromocode => ({
  usedDate: res.used_date,
  isActivated: res.is_activated,
  name: res.promocode_name,
  description: res.description,
  balanceType: res.balance_type,
  amount: res.amount,
  currency: res.currency,
});

export const adaptePromocodes = (res: IPromocodesRes): IPromocodes => ({
  count: res.count,
  items: res.items.map(adaptePromocode),
});

export const adapteUserSettingsToClient = (settings: UserSettingsServer): UserSettings => ({
  colorScheme: settings.color_scheme,
  timezone: settings.timezone,
  lang: settings.lang,
  balanceType: settings.balance_type,
  notifications: {
    betResults: settings.notifications.bet_results,
    withdrawals: settings.notifications.withdrawals,
    notifications: settings.notifications.notifications,
  },
});

export const adapteUserSettingsToServer = (settings: UserSettings): UserSettingsServer => ({
  'color_scheme': settings.colorScheme,
  'timezone': settings.timezone,
  'lang': settings.lang,
  'balance_type': settings.balanceType,
  'notifications': {
    'bet_results': settings.notifications.betResults,
    'withdrawals': settings.notifications.withdrawals,
    'notifications': settings.notifications.notifications,
  },
});

export const adapteFavoriteGame = (game: IFavoritesGameServer): IFavoriteGame => ({
  id: String(game.id),
  gameId: game.game_id,
  gameName: game.game_name,
  provider: game.provider,
  mainProvider: game.main_provider,
});

export const adapteFavoriteGames = (res: IFavoritesGameServer[]) =>
  res.map(adapteFavoriteGame);

export const adapteFavoriteMatch = (favorite: IFavoriteMatchServer): IFavoriteMatch => ({
  id: favorite.id,
  matchId: favorite.match_id,
  team1: favorite.team_1,
  team2: favorite.team_2,
  sportName: favorite.sport_name,
  sportId: favorite.sport_id,
  tournamentId: favorite.tournament_id,
  tournamentName: favorite.tournament_name,
  countryId: favorite.country_id,
  countryName: favorite.country_name,
  dataType: favorite.data_type,
});

export const adapteFavoritesMatches = (res: IFavoriteMatchServer[]) =>
  res.map(adapteFavoriteMatch);
