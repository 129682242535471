import { getCasinoGameImgByProvider } from '../utils/get-casino-game-img';
import { crashGamesType } from './const';
import {
  CasinoGame,
  InbetGamesResponse,
  MascotGameServer,
  PragmaticGameServer,
  RplGamesResponse,
  SmartsoftGame,
  VegasGameServer,
  DigitainGameServer,
  Lastwin,
  LastwinResponse,
  EndorphinaGame,
  AgtGameServer,
  FivemenGameServer,
  GoshGameServer,
} from './types';

const isCrashGame = (mainProvider: string, gameId: string) => !!crashGamesType[mainProvider]?.includes(gameId);

export const adaptePragmaticGame = (game: PragmaticGameServer): CasinoGame => ({
  id: game.game_id,
  gameId: game.game_id,
  name: game.game_name,
  link: `/games/pragmatic/${game.game_id}`,
  isFavorite: false,
  preview: `https://common-static.ppgames.net/game_pic/rec/325/${game.game_id}.png`,
  category: [],
  type: [isCrashGame('pragmatic', game.game_id) ? 'crash games' : '']
    .filter((item) => !!item),
  provaider: 'pragmatic',
  mainProvider: 'pragmatic',
});

export const adaptePragmaticGames = (res: PragmaticGameServer[]) =>
  res.map(adaptePragmaticGame);

export const adapteSmartsoftGame = (game: SmartsoftGame): CasinoGame => ({
  id: `${game.game_id}`,
  gameId: `${game.game_id}`,
  name: game.title,
  link: `/games/smartsoft/${game.game_name}/${game.game_category}`,
  isFavorite: false,
  preview: `/img/games/smartsoft/${game.game_name}.jpg`,
  category: [],
  type: [game.game_category, isCrashGame('smartsoft', `${game.game_id}`) ? 'crash games' : '']
    .filter((item) => !!item),
  provaider: game.provider,
  mainProvider: 'smartsoft',
});

export const adapteSmartsoftGames = (res: SmartsoftGame[]) =>
  res.map(adapteSmartsoftGame);

export const adapteMascotGame = (game: MascotGameServer): CasinoGame => ({
  id: `${game.id}`,
  gameId: `${game.id}`,
  name: game.name,
  link: `/games/mascot/${game.id}`,
  isFavorite: false,
  preview: `/img/games/mascot/${game.id}.png`,
  category: [],
  type: [game.type, isCrashGame('mascot', `${game.id}`) ? 'crash games' : '']
    .filter((item) => !!item),
  provaider: game.section_id,
  mainProvider: 'mascot',
});

export const adapteMascotGames = (res: MascotGameServer[]) =>
  res.map(adapteMascotGame);

export const adapteVegasGame = (game: VegasGameServer): CasinoGame => ({
  id: `${game.id}`,
  gameId: `${game.id}`,
  name: game.name,
  link: `/games/vegas/${game.id}`,
  isFavorite: false,
  preview: `/img/games/vegas/${game.id}.jpg`,
  category: [],
  type: [game.type, isCrashGame('vegas', `${game.id}`) ? 'crash games' : '']
    .filter((item) => !!item),
  provaider: game.section_id,
  mainProvider: 'vegas',
});

export const adapteVegasGames = (res: VegasGameServer[]) => {
  const result = res.reduce((acc, game) => {
    if (!game.name.toLowerCase().includes('aviatrix')) {
      acc.push(adapteVegasGame(game));
    }

    return acc;
  }, [] as CasinoGame[]);

  return result;
};


export const adapteInbetGames = (res: InbetGamesResponse): CasinoGame[] =>
  Object.entries(res)
    .map(([gameId, game]) => ({
      id: gameId,
      gameId,
      name: game.name,
      link: `/games/inbetgames/${gameId}`,
      isFavorite: false,
      preview: `/inbetcdn/thumb/300x188/${gameId}.jpeg`,
      category: [],
      type: [
        game.type.toLowerCase() === 'slot' ? 'Slots' : game.type,
        isCrashGame('inbetgames', `${gameId}`) ? 'crash games' : ''
      ].filter((item) => !!item),
      provaider: game.engine ?? 'inbetgames',
      mainProvider: 'inbetgames'
    }));

export const adapteRplGames = (res: RplGamesResponse): CasinoGame[] =>
  Object.entries(res)
    .map(([gameId, game]) => ({
      id: gameId,
      gameId,
      name: game.name,
      link: `/games/rplgames/${gameId}`,
      isFavorite: false,
      preview: `/rplcdn/thumb/300x188/${gameId}.jpeg`,
      category: [],
      type: [
        game.type.toLowerCase() === 'slot' ? 'Slots' : game.type,
        isCrashGame('rplgames', `${gameId}`) ? 'crash games' : ''
      ].filter((item) => !!item),
      provaider: game.engine ?? 'rplgames',
      mainProvider: 'rplgames'
    }));

export const adapteDigitainGame = (game: DigitainGameServer): CasinoGame => ({
  id: `${game.id}`,
  gameId: `${game.id}`,
  name: game.name,
  link: `/games/digitain/${game.id}`,
  isFavorite: false,
  preview: '',
  category: [],
  type: [isCrashGame('digitain', `${game.id}`) ? 'crash games' : '']
    .filter((item) => !!item),
  provaider: 'digitain',
  mainProvider: 'digitain',
});

export const adapteDigitainGames = (res: DigitainGameServer[]) =>
  res.map(adapteDigitainGame);

export const adapreEndorphinaGame = (game: EndorphinaGame): CasinoGame => ({
  id: `${game.id}`,
  gameId: `${game.id}`,
  name: game.description,
  link: `/games/endorphina/${game.id}`,
  isFavorite: false,
  preview: `/img/games/endorphina/${game.description}.png`,
  category: [],
  type: [],
  provaider: 'endorphina',
  mainProvider: 'endorphina',
});

export const adapreEndorphinaGames = (res: EndorphinaGame[]) =>
  res.map(adapreEndorphinaGame);

export const adapteAgtGame = (game: AgtGameServer): CasinoGame => ({
  id: game.game_id,
  gameId: game.game_id,
  name: game.visible_name,
  link: `/games/agt/${game.game_id}`,
  isFavorite: false,
  preview: game.lobby[0]?.[0]?.src ?? '',
  category: [game.category].filter((item) => !!item),
  type: [game.game_type].filter((item) => !!item)
    .map((item) => item === 'slot' ? 'slots' : ''),
  provaider: 'agt',
  mainProvider: 'agt',
});

export const adapteAgtGames = (res: AgtGameServer[]) =>
  res.map(adapteAgtGame);

export const adapteFivemenGame = (game: FivemenGameServer): CasinoGame => ({
  id: game.gameId,
  gameId: game.gameId,
  name: game.gameTitle,
  link: `/games/fivemen/${game.gameId}`,
  isFavorite: false,
  preview: `/img/games/fivemen/${game.gameId.split(':')[2]}.png`,
  category: [],
  type: [],
  provaider: '5Men',
  mainProvider: '5men',
});

export const adapteFivemenGames = (res: FivemenGameServer[]) =>
  res.map(adapteFivemenGame);

export const adapteGoshGame = (game: GoshGameServer): CasinoGame => ({
  id: String(game['game_id']),
  gameId: String(game['game_id']),
  name: game['game_name'],
  link: `/games/gosh/${game['game_id']}`,
  isFavorite: false,
  preview: `/img/games/gosh/${game.game_name}.png`,
  category: [],
  type: [game['game_type']?.toLowerCase()].filter((item) => !!item),
  provaider: game['game_studio_name'],
  mainProvider: 'gosh',
});

export const adapteGoshGames = (res: GoshGameServer[]): CasinoGame[] =>
  res.map(adapteGoshGame);

export const adapteLastwin = (res: LastwinResponse): Lastwin => ({
  provider: res.provider,
  gameId: res.game_id,
  gameName: res.game_name,
  betAmount: res.bet_amount,
  winAmount: res.win_amount,
  currency: res.currency,
  link: '',
  preview: getCasinoGameImgByProvider(
    res.provider,
    res.provider === 'smartsoft' ? res.game_name : res.game_id
  ),
});

export const adapteLastwins = (res: LastwinResponse[]): Lastwin[] => res.map(adapteLastwin);
