import { useTranslation } from 'react-i18next';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { useVerifyTelegramQuery } from '@/modules/user/store/user-api';
import Button from '@/shared/components/button/button';

import './telegram-change.scss';

const b = bemCn('telegram-change');

const TelegramVerifyBtn = () => {
  const { t } = useTranslation();
  const { data: telegramVerifyLink, isLoading: isTelegramVerifyLinkLoading } = useVerifyTelegramQuery();

  const handleClick = () => {
    window.open(telegramVerifyLink, '_blank');
    window.location.reload();
  };

  return (
    <Button className={b('btn')}
      type='button'
      variant="bordered"
      loading={isTelegramVerifyLinkLoading}
      onClick={handleClick}
    >
      {t('settings.verify-telegram', 'Подтвердить')}
    </Button>
  );
};

export default TelegramVerifyBtn;
