import { createSelector } from '@reduxjs/toolkit';
import userApi from './user-api';
import { currenciesTitles } from '../../../shared/utils/constants/currency';
import { NameSpace } from '@/shared/utils/constants/common';
import type { UserData, Wallet, UserModal } from './types';
import { DataType } from '@/modules/line';

const emptyUserWallet: Wallet = {
  isPrimary: false,
  currency: '',
  amount: 0,
  bonusAmount: 0,
  cashbackLine: 0,
  cashbackCasino: 0,
  maxBetAmount: 0,
};

const empryUserData: UserData = {
  id: '',
  login: null,
  email: null,
  phone: null,
  phoneCountry: null,
  telegramNickname: null,
  telegramVerified: false,
  name: null,
  role: 'user',
  created: '',
};

export const getUserWallets = createSelector(
  userApi.endpoints.getUserWallets.select(),
  (result) => result
);

export const getUserCurrencies = createSelector(
  getUserWallets,
  (wallets) => wallets.data?.map((currencyItem) => currencyItem.currency) ?? [],
);

export const getUserMainData = (state: RootState) => {
  const { data } = userApi.endpoints.checkAuth.select()(state);

  return data;
};

export const getPrimaryWallet = createSelector(
  getUserWallets,
  ({ data = [] }) => {
    const primaryWallet = data.find((wallet) => wallet.isPrimary);

    return primaryWallet;
  }
);

export const getUserCurrency = createSelector(
  getPrimaryWallet,
  (primaryWallet) => primaryWallet?.currency
);

export const getUserData = createSelector(
  getUserMainData,
  getPrimaryWallet,
  (userData, primaryWallet) => ({
    ...empryUserData,
    ...userData,
    wallet: {
      ...emptyUserWallet,
      ...primaryWallet,
    }
  })
);

export const getUseRole = createSelector(
  getUserMainData,
  (mainUserData) => mainUserData?.role
);

export const getUseExtraWallets = createSelector(
  getUserWallets,
  ({ data = [] }) => {
    const useExtraWallets = data
      .filter((wallet) => !wallet.isPrimary)
      .map((wallet) => ({
        ...wallet,
        label: currenciesTitles[wallet.currency]?.title ?? wallet.currency,
        symbol: currenciesTitles[wallet.currency]?.symbol ?? ''
      }));

    return useExtraWallets;
  }
);


export const getUserGeo = createSelector(
  userApi.endpoints.getUserGeo.select(),
  (result) => result,
);

export const getUserGeoCountry = createSelector(
  getUserGeo,
  ({ data }) => data?.country ?? ''
);

export const getUserModal = (state: RootState): UserModal =>
  state[NameSpace.User].activeModal;

export const getFavoritesGames = createSelector(
  userApi.endpoints.getFavoritesGames.select(),
  ({ data = [] }) => data
);

export const getFavoritesMathes = createSelector(
  userApi.endpoints.getFavoriteMatches.select(),
  ({ data = [] }) => data
);

export const getFavoritesMatchesByDatatype = createSelector(
  getFavoritesMathes,
  (_: RootState, dataType: DataType) => dataType,
  (matches, dataType) => matches.filter((item) => item.dataType === dataType)
);

export const getFavoritesMatchesCount = createSelector(
  getFavoritesMathes,
  (_: RootState, dataType?: DataType) => dataType,
  (matches, dataType) => {
    if (dataType) {
      return matches.filter((item) => item.dataType === dataType).length;
    }

    return matches.length;
  }
);

export const getFavotieMatchId = (state: RootState, eventMainId: string) => {
  const favoritesMatches = getFavoritesMathes(state);
  const favoriteMatch = favoritesMatches.find((match) => match.matchId === Number(eventMainId));

  return favoriteMatch?.id;
};
